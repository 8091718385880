var Map = {
    init: function() {
        var MY_MAPTYPE_ID = 'addwork';
        var featureOpts = [{
            "featureType": "landscape",
            "elementType": "all",
            "stylers": [{
                "color": "#cbd5ed"
            }]
        }, {
            "featureType": "road",
            "elementType": "all",
            "stylers": [{
                "color": "#ffffff"
            }]
        }, {
            "featureType": "road.highway.controlled_access",
            "elementType": "labels",
            "stylers": [{
                "color": "#000000"
            }]
        }, {
            "featureType": "road.arterial",
            "elementType": "labels",
            "stylers": [{
                "color": "#000000"
            }, {
                "weight": 0.1
            }]
        }, {
            "featureType": "road.local",
            "elementType": "labels.text",
            "stylers": [{
                "color": "#000000"
            }, {
                "weight": 0.16
            }]
        }];
        var styledMapOptions = {
            name: 'AddWork.sk'
        };

        function initialize() {
            var myLatlng = new google.maps.LatLng(48.144103, 17.119233);
            var mapOptions = {
                zoom: 15,
                center: myLatlng,
                mapTypeControlOptions: {
                    mapTypeIds: [google.maps.MapTypeId.ROADMAP, MY_MAPTYPE_ID]
                },
                mapTypeId: MY_MAPTYPE_ID
            }
            var map = new google.maps.Map(document.getElementById('map'), mapOptions);

            var marker = new google.maps.Marker({
                position: myLatlng,
                map: map,
                icon: '../images/addwork-marker.png',
                animation: google.maps.Animation.DROP,
                title: 'AddWork.sk'
            });
            var customMapType = new google.maps.StyledMapType(featureOpts, styledMapOptions);

            map.mapTypes.set(MY_MAPTYPE_ID, customMapType);
        }

        google.maps.event.addDomListener(window, 'load', initialize);

    }
}

var Company = {
    init: function() {
        var qsRegex;

        var $grid = $('.companies-wrapper.searchable').isotope({
            itemSelector: '.company-link-content',
            layoutMode: 'masonry',
            masonry: {
                columnWidth: '.company-link'
            },
            filter: function() {
                console.log($(this).data('name'));
                return qsRegex ? $(this).data('name').match(qsRegex) : true;
            },
            stamp: '.stamp',

        })
        var $quicksearch = $('.quicksearch').keyup(debounce(function() {
            qsRegex = new RegExp($quicksearch.val(), 'gi');
            $grid.isotope();
        }, 200));

        function debounce(fn, threshold) {
            var timeout;
            return function debounced() {
                if (timeout) {
                    clearTimeout(timeout);
                }

                function delayed() {
                    fn();
                    timeout = null;
                }
                timeout = setTimeout(delayed, threshold || 100);
            }
        }
    }
};

var Galleries = {
    init: function() {
        $('.fancybox').on('click', function() {
            var photos = [];
            $($(this).data('galid')).each(function() {
                photos.push($(this).attr('href'));
            });
            $.fancybox.open(
                photos
            );
            return false;
        });
    },
};

var Site = {

    video: function(id) {

        if (id === 0) {
            if (is.desktop()) {
                $('.video-wrapper').tubular({
                    videoId: '2t0XuJEnTV8'
                });
            }
        }else{
            if (is.desktop()) {
                $('.video-wrapper').tubular({
                    videoId: id
                });
            }
        }

    },

    init: function() {

        $('.track-event').on('click', function(){
            var $this = $(this);
            ga('send', 'event', $this.attr('data-event-category'), $this.attr('data-event-action'), $this.attr('data-event-label'), $this.attr('data-event-value'));
            console.log('tracked');
        })

        $('.close-btn').on('click', function(){
            $.fancybox.close();
            return false;
        })

        $('.down-arrow').on('click', function() {
            y = $(window).scrollTop();

            $('html, body').stop().animate({
                scrollTop: y + 500,
            })
            return false;
        });
        if ($(window).width() < 767) {

            // $('.readmore').readmore({
            //   speed: 75,

            //   embedCSS: true,
            //   blockCSS: 'display: block; width: 100%;',
            //   lessLink: '<a href="#" style="display:none;">Read more</a>',
            //   moreLink: '<a href="#" class="three-dots"><img src="'+base_url+'/images/three-dots.png"></a>'
            // });
            //
            // $('.readmore').find('div').html(function() { // for every paragraph in container
            //     var exposer = '<a href="#" class="expose three-dots"><img src="'+base_url+'/images/three-dots.png" width="20"></a>',
            //         // link to insert
            //         content = $(this).html().split(''),
            //         cutLength = 290,
            //         // choose the cutoff point
            //         anterior = content.slice(0, cutLength).join(''),
            //         dorsal = content.slice(cutLength).join(''),
            //         joined =
            //         anterior + exposer + '<span class="dorsal">' + dorsal + '</span>'; // assemble the new contents
            //     return joined;
            // }).on('click', '.expose', function(e) {
            //     e.preventDefault();
            //     var $thisp = $(this).closest('div');
            //     //$('.readmore').find('div').not($thisp).hide(); // hide others
            //     $thisp // for the enclosing paragraph
            //     .find('.dorsal') // select the hidden piece
            //     .show() // show it
            //     .end() // back to the paragraph
            //     .find('.expose') // find the "show more" link
            //     .hide(); // hide it
            //     $('.dots').hide();
            // });
        }

        $('.homepage-logos').owlCarousel({
            'loop': false,
            responsive: {
                0: {
                    items: 1,
                    nav: false
                },
                600: {
                    items: 1,
                    nav: false
                },

            }
        });

        $('.homepage-logos-alternate').owlCarousel({
            'loop': false,
            responsive: {
                0: {
                    items: 1,
                    nav: false,
                    autoplay: true,
                    autoplaySpeed: 500,
                    loop: true,
                },
                600: {
                    items: 3,
                    nav: false,

                },

            }
        });

        $('.jobs-wrapper').infinitescroll({

            navSelector: ".pagination",
            nextSelector: ".jobs-load-more",
            itemSelector: ".job-item",
            img: false,
            animate: false,
            debug: true,
            msgText: "Nahrávam",
            donetext: "Vsetko",
        }, function(){
            $('.loading-more').hide();
            $('.jobs-load-more').hide();
        });
        // $(window).unbind('.infscr');
        // $(".jobs-load-more").click(function(){
        //     $('.jobs-wrapper').infinitescroll('retrieve');
        //         return false;
        // });

        $('.burger-menu').on('click', function() {
            $(this).toggleClass('active');
            $('.logo-link').toggleClass('hidden');
            $('.top-menu').fadeToggle(200);
            // document.addEventListener('touchmove', function (e) {
            //     e.preventDefault();
            // });
            return false;
        })

        $('.back-to-top').on('click', function() {
            $('html, body').stop().animate({
                scrollTop: $("body").offset().top
            }, 2000);

            return false;
        })

        var url = base_url;



        /* Single job position needs to be recoded */
        $('.fancybox-single').fancybox({

            fitToView   : true,
        });
        $('.fancybox-media').fancybox({
            padding:0,
            helpers : {
                media : {}
            },
            overlay: {
              locked: false
            }
        })
        $('#formular-zivotopis').on('submit', function(e) {
            e.preventDefault;
             $('#formular-zivotopis button').text('Prosím čakajte');
            $.ajax({
                type: "POST",
                dataType: 'json',
                url: $(this).attr('action'),
                data: $('#formular-zivotopis').serialize(),
                success: function(data) {
                    $('#formular-zivotopis').fadeOut(1000, function(){
                        $('.success-message').fadeIn(200);
                    });
                    if(is.ios()){
                        $('body').scrollTop(0);
                    }
                    $('#formular-zivotopis button').text('Odoslať');
                },
                error: function(data) {
                    var errors = $.parseJSON(data.responseText);
                    $('.error-box').empty();

                    $.each(errors, function(key, value) {
                        $('.'+key+'.input-box').addClass('redBorder');
                    })
                    $('#formular-zivotopis button').text('Odoslať');
                }
            })
            return false;
        })
        $('#formular-kontakt').on('submit', function(e) {
            e.preventDefault;
            $('#formular-kontakt button').text('Prosím čakajte');
            $.ajax({
                type: "POST",
                dataType: 'json',
                url: $(this).attr('action'),
                data: $('#formular-kontakt').serialize(),
                success: function(data) {
                    if(is.ios()){
                        $('body').scrollTop(0);
                    }
                    $('#formular-kontakt').fadeOut(500, function(){
                         $('.success-message').fadeIn(200);
                         $('.hide-this').fadeOut(200);
                    });

                    $('#formular-kontakt button').text('Odoslať');

                },
                error: function(data) {
                    var errors = $.parseJSON(data.responseText);
                    $('.error-box').empty();
                    $('#formular-kontakt button').text('Odoslať');
                    $.each(errors, function(key, value) {
                        $('.'+key+'.input-box').empty().addClass('redBorder');
                    })
                }
            })
            return false;
        })


        $('#formular-twentyfour').on('submit', function(e) {
            e.preventDefault;
            $.ajax({
                type: "POST",
                dataType: 'json',
                url: $(this).attr('action'),
                data: $('#formular-twentyfour').serialize(),
                success: function(data) {
                    $('#formular-twentyfour').fadeOut(1000);
                },
                error: function(data) {
                    var errors = $.parseJSON(data.responseText);
                    $.each(errors, function(key, value) {
                        $('p.errors.second-form').append(value);
                    })
                }
            })
            return false;
        })

        $('#fileupload').fileupload({
                url: url + '/upload_file',
                dataType: 'json',
                paramName: 'userfile',
                singleFileUploads: true,
                done: function(e, data) {
                    $.each(data.result.files, function(index, file) {
                        $('#formular-zivotopis .cv_id').val(file.id);
                    });
                    $('.fileupload-first').addClass('greenBorder');
                },
                progressall: function(e, data) {
                    var progress = parseInt(data.loaded / data.total * 100, 10);
                    $('.progress .progress-bar-first').css({
                        'width': progress + '%'
                    });
                }
            }).prop('disabled', !$.support.fileInput)
            .parent().addClass($.support.fileInput ? undefined : 'disabled');

        $('#fileupload_second').fileupload({
                url: url + '/upload_file',
                dataType: 'json',
                paramName: 'userfile',
                singleFileUploads: true,
                done: function(e, data) {
                    $.each(data.result.files, function(index, file) {
                        $('#formular-zivotopis .motivational_id').val(file.id);
                    });
                    $('.fileupload-second').addClass('greenBorder');
                },
                progressall: function(e, data) {
                    var progress = parseInt(data.loaded / data.total * 100, 10);
                    $('.progress .progress-bar-second').css({
                        'width': progress + '%'
                    });
                }
            }).prop('disabled', !$.support.fileInput)
            .parent().addClass($.support.fileInput ? undefined : 'disabled');

        $('#fileupload_third').fileupload({
                url: url + '/upload_file',
                dataType: 'json',
                paramName: 'userfile',
                singleFileUploads: true,
                done: function(e, data) {
                    $.each(data.result.files, function(index, file) {
                        $('#formular-twentyfour .cv_id').val(file.id);
                    });
                    $('#fileupload_third').addClass('greenBorder');
                },
                progressall: function(e, data) {
                    var progress = parseInt(data.loaded / data.total * 100, 10);
                    $('.progress .progress-bar-first').css({
                        'width': progress + '%'
                    });
                }
            }).prop('disabled', !$.support.fileInput)
            .parent().addClass($.support.fileInput ? undefined : 'disabled');

        $('#fileupload_fourth').fileupload({
                url: url + '/upload_file',
                dataType: 'json',
                paramName: 'userfile',
                singleFileUploads: true,
                done: function(e, data) {
                    $.each(data.result.files, function(index, file) {
                        $('#formular-twentyfour .motivational_id').val(file.id);
                    });
                },
                progressall: function(e, data) {
                    var progress = parseInt(data.loaded / data.total * 100, 10);
                    $('.progress .progress-bar-second').css({
                        'width': progress + '%'
                    });
                }
            }).prop('disabled', !$.support.fileInput)
            .parent().addClass($.support.fileInput ? undefined : 'disabled');

        /* EOF Single job position needs to be recoded */


        $('.newsletter-btn').on('click', function() {
            $.ajax({
                url: base_url + '/save_newsletter',
                method: 'POST',
                data: {
                    _token: $('#_token').val(),
                    email: $('#newsletter_box').val()
                }
            }).success(function() {
                $('#newsletter_box').hide(0, function() {
                    $('.newsletter-box-right .success').animate({
                        'opacity': 1
                    }, 1000);
                    $('.newsletter-btn').attr('disabled', true);
                });

            }).error(function() {
                $('#newsletter_box').addClass('red-border');
            })
            return false;
        })


    }
}

$(function() {
    Company.init();
    Site.init();

})
